import React from "react"

import styled from "styled-components"
import Categories from "../../containers/categories"
import { Link } from "gatsby"
import useLang from "../../hooks/useLang"
import { Global, css } from "@emotion/core"

const Cards = styled.div`
  display: flex;
  max-width: 1000px;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Card = styled(Link)`
  flex: 0 1 450px;
  display: flex;
  position: relative;
  margin: 35px 15px;
  background: gray;
  background: linear-gradient(transparent, #00000014, #000000c7, #000000c7),
    url(${({ img }) => img});
  background-size: contain, cover;
  height: 450px;
  transition: all 400ms linear;
  position: relative;
  filter: grayscale(100%);

  :hover {
    filter: grayscale(0);
  }

  @media screen and (max-width: 500px) {
    height: 400px;
  }
  @media screen and (max-width: 400px) {
    height: 300px;
  }
  @media screen and (max-width: 300px) {
    height: 200px;
  }
`

const CardContent = styled.div`
  position: absolute;
  padding: 35px;
  color: white;
  font-weight: lighter;
  bottom: 0;
  right: 0;
  left: 0;
  li {
    list-style: none;
  }
`

const CoursesPage = ({ articles }) => {
  const isAr = useLang() === "ar"
  const noDataText = isAr ? "لا توجد بيانات" : "No Data Found"

  return (
    <div style={{ paddingTop: 80 }}>
      <div className="container">
        <Categories collectionType="courses" />
        <Global
          styles={css`
            .gatsby-highlight {
              overflow: auto;
              border-radius: 20px;
              background-color: muted;
              padding: 5px 20px;
              box-shadow: 10px 5px 15px #eee;
              margin-bottom: 50px;
            }
            .gatsby-highlight,
            .gatsby-highlight * {
              direction: ltr;
            }
            .gatsby-highlight .builtin,
            .gatsby-highlight .keyword {
              color: primary;
            }
            .gatsby-highlight .operator,
            .gatsby-highlight .function {
              color: secondary;
            }
          `}
        />
        <Cards gap="35px" cardWidth="340px">
          {articles.length > 0 ? (
            articles.map(({ id, image, title, categories, slug }) => (
              <Card
                to={isAr ? "/ar" + slug : slug}
                img={image?.childImageSharp.fluid.src || {}}
                key={id}
              >
                <CardContent>
                  <ul>
                    {categories.map(category => (
                      <li key={category}>{category}</li>
                    ))}
                  </ul>
                  <h2>{title}</h2>
                </CardContent>
              </Card>
            ))
          ) : (
            <div style={{ padding: 50 }}>{noDataText}</div>
          )}
        </Cards>
      </div>
    </div>
  )
}
export default CoursesPage
