import React from "react"

import Layout from "../components/layout"
import CoursesMainArea from "../components/CoursesMainArea"
import CoursesPage from "../components/CoursesPage"
import { graphql } from "gatsby"
import Arrow from "../components/Arrow"

const Category = ({ data, pageContext: { lang, nameAR, nameEN } }) => {
  const articales = data.allMarkdownRemark.edges.map(
    ({ node }) => node.frontmatter
  )

  const title = lang === "ar" ? nameAR : nameEN
  return (
    <Layout title={title} lang={lang}>
      <CoursesMainArea title={title} description="" />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <CoursesPage articles={articales} />
        </div>
      </div>
    </Layout>
  )
}
export default Category

export const CategoryQuery = graphql`
  query($category: String!, $lang: String!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/courses/" }
        frontmatter: {
          categories: { in: [$category] }
          draft: { ne: true }
          lang: { eq: $lang }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            categories
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
